// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
}

.collapse__div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.top__bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 20px;
  width: 100%;
  height: 52px;
  background-color: #FF6060;
  border-radius: 5px;
}

.collapse__title {
  color: white;
  font-size: 24px;
}

.arrow__closed {
  width: 24px;
  object-fit: none;
}

.bottom__bar {
  background: #F6F6F6;
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
  max-height: 0;
  transition: max-height 300ms;
}

.collapse__text {
  color: #FF6060;
  max-height: 300px;
  padding: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Collapse/Collapse.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,WAAA;AACJ;;AAEA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,YAAA;EACA,eAAA;AACJ;;AAEA;EACI,WAAA;EACA,gBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,4BAAA;AACJ;;AAEA;EACI,cAAA;EACA,iBAAA;EACA,aAAA;AACJ","sourcesContent":["* {\n    box-sizing: border-box;\n}\n\n.collapse__div {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}\n\n.top__bar {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding-left: 15px;\n    padding-right: 20px;\n    width: 100%;\n    height: 52px;\n    background-color: #FF6060;\n    border-radius: 5px;\n}\n\n.collapse__title {\n    color: white;\n    font-size: 24px;\n}\n\n.arrow__closed {\n    width: 24px;\n    object-fit: none;\n}\n\n.bottom__bar {\n    background: #F6F6F6;\n    border-radius: 5px;\n    width: 100%;\n    overflow: hidden;\n    max-height: 0;\n    transition: max-height 300ms;\n}\n\n.collapse__text {\n    color: #FF6060;\n    max-height: 300px;\n    padding: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
