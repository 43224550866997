// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property__card {
  display: flex;
  box-sizing: border-box;
  height: 340px;
  width: 340px;
  border-radius: 10px;
}
@media screen and (max-width: 768px) {
  .property__card {
    width: 100%;
  }
}

.shadow__box {
  background: linear-gradient(#FFFFFF, #000000);
  height: 340px;
  width: 340px;
  border-radius: 10px;
  position: relative;
  bottom: 340px;
  opacity: 0.5;
}
@media screen and (max-width: 768px) {
  .shadow__box {
    width: 100%;
  }
}

.property__title {
  display: flex;
  align-items: flex-end;
  font-size: 18px;
  color: #F6F6F6;
  position: relative;
  left: 20px;
  bottom: 410px;
  width: 300px;
}

.card__link {
  text-decoration: none;
}

.property__cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.card__link {
  height: 340px;
  width: 340px;
}
@media screen and (max-width: 768px) {
  .card__link {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Card/Card.scss","webpack://./src/styles/utils/variables.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;AADJ;ACHI;EDDJ;IAQQ,WAAA;EAAN;AACF;;AAGA;EACI,6CAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;AAAJ;AClBI;EDWJ;IAUQ,WAAA;EACN;AACF;;AAEA;EACI,aAAA;EACA,qBAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;EACA,UAAA;EACA,aAAA;EACA,YAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,YAAA;AACJ;AClDI;ED+CJ;IAKQ,WAAA;EAEN;AACF","sourcesContent":["@import \"../../styles/utils/variables.scss\";\n\n.property__card {\n    display: flex;\n    box-sizing: border-box;\n    height: 340px;\n    width: 340px;\n    border-radius: 10px;\n\n    @include media-query($tablet-width) {\n        width: 100%\n    }\n}\n\n.shadow__box {\n    background: linear-gradient(#FFFFFF, #000000);\n    height: 340px;\n    width: 340px;\n    border-radius: 10px;\n    position: relative;\n    bottom: 340px;\n    opacity: 0.5;\n\n    @include media-query($tablet-width) {\n        width: 100%\n    }\n}\n\n.property__title {\n    display: flex;\n    align-items: flex-end;\n    font-size: 18px;\n    color: #F6F6F6;\n    position: relative;\n    left: 20px;\n    bottom: 410px;\n    width: 300px;\n}\n\n.card__link {\n    text-decoration: none;\n}\n\n.property__cover {\n    object-fit: cover;\n    width: 100%;\n    height: 100%;\n    border-radius: 10px;\n}\n\n.card__link {\n    height: 340px;\n    width: 340px;\n\n    @include media-query($tablet-width) {\n        width: 100%\n    }\n}","$color-primary: #FF6060;\n\n@mixin media-query($width) {\n    @media screen and (max-width: $width) {\n        @content;\n    }\n}\n\n$tablet-width: 768px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
