// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 209px;
  background-color: black;
}

.footer__logo {
  object-fit: none;
  width: 122px;
}

.footer__text {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,YAAA;AACJ;;AAEA;EACI,YAAA;AACJ","sourcesContent":[".footer {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 209px;\n    background-color: black;\n}\n\n.footer__logo {\n    object-fit: none;\n    width: 122px;\n}\n\n.footer__text {\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
