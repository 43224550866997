// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rating {
  display: flex;
  justify-content: flex-end;
}

.star__grey,
.star__red {
  width: 24px;
  height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/Rating/Rating.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;AACJ;;AAEA;;EAEI,WAAA;EACA,YAAA;AACJ","sourcesContent":[".rating {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.star__grey,\n.star__red {\n    width: 24px;\n    height: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
