// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main__div {
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
  margin-top: 48px;
  margin-bottom: 50px;
  padding-top: 56px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 43px;
  background-color: #F6F6F6;
  border-radius: 25px;
}
@media screen and (max-width: 768px) {
  .main__div {
    padding: 0;
    gap: 20px;
    background-color: unset;
    border-radius: unset;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.scss","webpack://./src/styles/utils/variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,eAAA;EACA,SAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,oBAAA;EACA,yBAAA;EACA,mBAAA;AADF;ACTI;EDDJ;IAcI,UAAA;IACA,SAAA;IACA,uBAAA;IACA,oBAAA;EAAF;AACF","sourcesContent":["@import \"./styles/utils/variables.scss\";\n\n.main__div {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 60px;\n  margin-top: 48px;\n  margin-bottom: 50px;\n  padding-top: 56px;\n  padding-left: 50px;\n  padding-right: 50px;\n  padding-bottom: 43px;\n  background-color: #F6F6F6;\n  border-radius: 25px;\n\n  @include media-query($tablet-width) {\n    padding: 0;\n    gap: 20px;\n    background-color: unset;\n    border-radius: unset;\n  }\n}","$color-primary: #FF6060;\n\n@mixin media-query($width) {\n    @media screen and (max-width: $width) {\n        @content;\n    }\n}\n\n$tablet-width: 768px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
