// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header__div {
  display: flex;
  flex-direction: row;
  margin-top: 45px;
  margin-bottom: 50px;
  justify-content: space-between;
  align-items: center;
}

.nav__list {
  display: inline-block;
  list-style-type: none;
  text-decoration: none;
  padding-left: 57px;
  color: #FF6060;
}
@media screen and (max-width: 768px) {
  .nav__list {
    text-transform: uppercase;
    padding-left: 15px;
    font-size: 15px;
  }
}

.nav__list a {
  color: #FF6060;
  text-decoration: none;
}

a:active {
  color: #FF6060;
  text-decoration: none;
}

a:visited {
  color: #FF6060;
  text-decoration: none;
}

.active {
  text-decoration: underline;
}

.header__logo {
  width: 30%;
  max-width: 211px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.scss","webpack://./src/styles/utils/variables.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AADJ;;AAIA;EACI,qBAAA;EACA,qBAAA;EACA,qBAAA;EACA,kBAAA;EACA,cChBY;ADehB;ACZI;EDQJ;IAQQ,yBAAA;IACA,kBAAA;IACA,eAAA;EAAN;AACF;;AAIA;EACI,cC3BY;ED4BZ,qBAAA;AADJ;;AAIA;EACI,cChCY;EDiCZ,qBAAA;AADJ;;AAIA;EACI,cCrCY;EDsCZ,qBAAA;AADJ;;AAIA;EACI,0BAAA;AADJ;;AAIA;EACI,UAAA;EACA,gBAAA;AADJ","sourcesContent":["@import \"../../styles/utils/variables.scss\";\n\n.header__div {\n    display: flex;\n    flex-direction: row;\n    margin-top: 45px;\n    margin-bottom: 50px;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.nav__list {\n    display: inline-block;\n    list-style-type: none;\n    text-decoration: none;\n    padding-left: 57px;\n    color: $color-primary;\n\n    @include media-query($tablet-width) {\n        text-transform: uppercase;\n        padding-left: 15px;\n        font-size: 15px;\n    }\n\n}\n\n.nav__list a {\n    color: $color-primary;\n    text-decoration: none;\n}\n\na:active {\n    color: $color-primary;\n    text-decoration: none;\n}\n\na:visited {\n    color: $color-primary;\n    text-decoration: none;\n}\n\n.active {\n    text-decoration: underline;\n}\n\n.header__logo {\n    width: 30%;\n    max-width: 211px;\n}","$color-primary: #FF6060;\n\n@mixin media-query($width) {\n    @media screen and (max-width: $width) {\n        @content;\n    }\n}\n\n$tablet-width: 768px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
