// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .banner__div {

    display: flex;
    align-items: center;
    justify-content: center;
    height: 223px;
    border-radius: 25px;

    @include media-query(\$tablet-width) {
        height: 111px;
    }

} */
.banner__text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0;
  margin: 0;
  font-size: 48px;
  position: absolute;
}
@media screen and (max-width: 768px) {
  .banner__text {
    margin-left: 16px;
    font-size: 24px;
  }
}

.banner__img {
  border-radius: 25px;
  width: 100%;
  height: 223px;
  object-fit: cover;
}

.banner__shadow {
  display: flex;
  justify-content: center;
  background-color: black;
  height: 223px;
  width: 100%;
  border-radius: 25px;
  opacity: 0.3;
  position: absolute;
  top: 0;
}

@media screen and (max-width: 768px) {
  .banner__img,
  .banner__shadow {
    height: 111px;
  }
}

.banner__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Banner/Banner.scss","webpack://./src/styles/utils/variables.scss"],"names":[],"mappings":"AAEA;;;;;;;;;;;;GAAA;AAcA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,UAAA;EACA,SAAA;EACA,eAAA;EACA,kBAAA;AAFJ;ACnBI;EDaJ;IAWQ,iBAAA;IACA,eAAA;EADN;AACF;;AAIA;EACI,mBAAA;EACA,WAAA;EACA,aAAA;EACA,iBAAA;AADJ;;AAIA;EACI,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,aAAA;EACA,WAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;AADJ;;AC5CI;EDgDJ;;IAGQ,aAAA;EADN;AACF;;AAIA;EACI,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AADJ","sourcesContent":["@import \"../../styles/utils/variables.scss\";\n\n/* .banner__div {\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 223px;\n    border-radius: 25px;\n\n    @include media-query($tablet-width) {\n        height: 111px;\n    }\n\n} */\n\n.banner__text {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: white;\n    padding: 0;\n    margin: 0;\n    font-size: 48px;\n    position: absolute;\n\n    @include media-query($tablet-width) {\n        margin-left: 16px;\n        font-size: 24px;\n    }\n}\n\n.banner__img {\n    border-radius: 25px;\n    width: 100%;\n    height: 223px;\n    object-fit: cover;\n}\n\n.banner__shadow {\n    display: flex;\n    justify-content: center;\n    background-color: black;\n    height: 223px;\n    width: 100%;\n    border-radius: 25px;\n    opacity: 0.3;\n    position: absolute;\n    top: 0;\n}\n\n.banner__img,\n.banner__shadow {\n    @include media-query($tablet-width) {\n        height: 111px;\n    }\n}\n\n.banner__wrapper {\n    position: relative;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}","$color-primary: #FF6060;\n\n@mixin media-query($width) {\n    @media screen and (max-width: $width) {\n        @content;\n    }\n}\n\n$tablet-width: 768px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
