// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notfound {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #FF6060;
}

.fourofour {
  font-size: 288px;
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .fourofour {
    font-size: 96px;
    margin-bottom: 113px;
  }
}

.subtext {
  font-size: 36px;
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .subtext {
    font-size: 18px;
    margin-bottom: 11px;
  }
}

.homelink {
  font-size: 18px;
  text-decoration: underline;
  margin-top: 139px;
  margin-bottom: 139px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Notfound/Notfound.scss","webpack://./src/styles/utils/variables.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,cCNY;ADKhB;;AAIA;EACI,gBAAA;EACA,UAAA;EACA,SAAA;AADJ;ACRI;EDMJ;IAMQ,eAAA;IACA,oBAAA;EAAN;AACF;;AAGA;EACI,eAAA;EACA,UAAA;EACA,SAAA;AAAJ;ACpBI;EDiBJ;IAMQ,eAAA;IACA,mBAAA;EACN;AACF;;AAEA;EACI,eAAA;EACA,0BAAA;EACA,iBAAA;EACA,oBAAA;AACJ","sourcesContent":["@import \"../../styles/utils/variables.scss\";\n\n.notfound {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    color: $color-primary;\n}\n\n.fourofour {\n    font-size: 288px;\n    padding: 0;\n    margin: 0;\n\n    @include media-query($tablet-width) {\n        font-size: 96px;\n        margin-bottom: 113px;\n    }\n}\n\n.subtext {\n    font-size: 36px;\n    padding: 0;\n    margin: 0;\n\n    @include media-query($tablet-width) {\n        font-size: 18px;\n        margin-bottom: 11px;\n    }\n}\n\n.homelink {\n    font-size: 18px;\n    text-decoration: underline;\n    margin-top: 139px;\n    margin-bottom: 139px;\n}","$color-primary: #FF6060;\n\n@mixin media-query($width) {\n    @media screen and (max-width: $width) {\n        @content;\n    }\n}\n\n$tablet-width: 768px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
