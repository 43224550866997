// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  box-sizing: border-box;
  margin: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header__div,
.banner__div,
.main__div,
.property__div,
.about__div,
.banner__shadow,
.banner__wrapper {
  margin-left: 100px;
  margin-right: 100px;
}
@media screen and (max-width: 768px) {
  .header__div,
  .banner__div,
  .main__div,
  .property__div,
  .about__div,
  .banner__shadow,
  .banner__wrapper {
    margin-left: 20px;
    margin-right: 20px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}`, "",{"version":3,"sources":["webpack://./src/index.scss","webpack://./src/styles/utils/variables.scss"],"names":[],"mappings":"AAGA;EACE,sBAAA;EACA,SAAA;EACA,yBAAA;EACA,mCAAA;EACA,kCAAA;AADF;;AAMA;;;;;;;EAOE,kBAAA;EACA,mBAAA;AAHF;ACfI;EDUJ;;;;;;;IAWI,iBAAA;IACA,kBAAA;EAIF;AACF;;AACA;EACE,+EAAA;AAEF","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Montserrat');\n@import \"./styles/utils/variables.scss\";\n\nbody {\n  box-sizing: border-box;\n  margin: 0;\n  font-family: 'Montserrat';\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n// Act as a wrapper\n\n.header__div,\n.banner__div,\n.main__div,\n.property__div,\n.about__div,\n.banner__shadow,\n.banner__wrapper {\n  margin-left: 100px;\n  margin-right: 100px;\n\n  @include media-query($tablet-width) {\n    margin-left: 20px;\n    margin-right: 20px;\n  }\n}\n\n// ^^^^^^^^^^^^^^^^^^^\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}","$color-primary: #FF6060;\n\n@mixin media-query($width) {\n    @media screen and (max-width: $width) {\n        @content;\n    }\n}\n\n$tablet-width: 768px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
