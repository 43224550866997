// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about__div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 38px;
  gap: 30px;
  margin-bottom: 38px;
}

@media screen and (max-width: 768px) {
  .banner__about__mobile {
    height: 223px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/About/About.scss","webpack://./src/styles/utils/variables.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,SAAA;EACA,mBAAA;AADJ;;ACLI;EDSJ;IAEQ,aAAA;EADN;AACF","sourcesContent":["@import \"../../styles/utils/variables.scss\";\n\n.about__div {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin-top: 38px;\n    gap: 30px;\n    margin-bottom: 38px;\n}\n\n.banner__about__mobile {\n    @include media-query($tablet-width) {\n        height: 223px;\n    }\n}","$color-primary: #FF6060;\n\n@mixin media-query($width) {\n    @media screen and (max-width: $width) {\n        @content;\n    }\n}\n\n$tablet-width: 768px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
