// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel {
  position: relative;
  height: 415px;
}
@media screen and (max-width: 768px) {
  .carousel {
    height: 255px;
  }
}

.carousel__div {
  height: 100%;
}

.slide {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 25px;
}

.left__arrow,
.right__arrow {
  width: 47px;
  height: 80px;
}

.left__arrow {
  position: absolute;
  top: 40%;
  left: 20px;
}
@media screen and (max-width: 768px) {
  .left__arrow {
    top: 35%;
  }
}

.right__arrow {
  position: absolute;
  top: 40%;
  right: 20px;
}
@media screen and (max-width: 768px) {
  .right__arrow {
    top: 35%;
  }
}

.current__slide {
  position: absolute;
  left: 48%;
  bottom: 0;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Carousel/Carousel.scss","webpack://./src/styles/utils/variables.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,aAAA;AADJ;ACAI;EDDJ;IAKQ,aAAA;EAAN;AACF;;AAGA;EACI,YAAA;AAAJ;;AAIA;EACI,YAAA;EACA,WAAA;EACA,iBAAA;EACA,mBAAA;AADJ;;AAIA;;EAEI,WAAA;EACA,YAAA;AADJ;;AAIA;EACI,kBAAA;EACA,QAAA;EACA,UAAA;AADJ;AC5BI;ED0BJ;IAMQ,QAAA;EAAN;AACF;;AAGA;EACI,kBAAA;EACA,QAAA;EACA,WAAA;AAAJ;ACvCI;EDoCJ;IAMQ,QAAA;EACN;AACF;;AAEA;EACI,kBAAA;EACA,SAAA;EACA,SAAA;EACA,YAAA;AACJ","sourcesContent":["@import \"../../styles/utils/variables.scss\";\n\n.carousel {\n    position: relative;\n    height: 415px;\n\n    @include media-query($tablet-width) {\n        height: 255px;\n    }\n}\n\n.carousel__div {\n    height: 100%;\n\n}\n\n.slide {\n    height: 100%;\n    width: 100%;\n    object-fit: cover;\n    border-radius: 25px;\n}\n\n.left__arrow,\n.right__arrow {\n    width: 47px;\n    height: 80px;\n}\n\n.left__arrow {\n    position: absolute;\n    top: 40%;\n    left: 20px;\n\n    @include media-query($tablet-width) {\n        top: 35%;\n    }\n}\n\n.right__arrow {\n    position: absolute;\n    top: 40%;\n    right: 20px;\n\n    @include media-query($tablet-width) {\n        top: 35%;\n    }\n}\n\n.current__slide {\n    position: absolute;\n    left: 48%;\n    bottom: 0;\n    color: white;\n}","$color-primary: #FF6060;\n\n@mixin media-query($width) {\n    @media screen and (max-width: $width) {\n        @content;\n    }\n}\n\n$tablet-width: 768px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
