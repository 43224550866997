// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
}

.bullet {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  color: white;
  background-color: #FF6060;
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Tag/Tag.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AAEA;EACI,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;AACJ","sourcesContent":["* {\n    box-sizing: border-box;\n}\n\n.bullet {\n    display: flex;\n    flex-wrap: nowrap;\n    justify-content: center;\n    align-items: center;\n    padding-left: 30px;\n    padding-right: 30px;\n    color: white;\n    background-color: #FF6060;\n    border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
